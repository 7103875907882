<template>
  <v-app id="main">
    <v-main>
      <router-view></router-view>
      <no-auth-modal />
      <cp-isc-double-btn-modal
        v-model="isShowModalBtn"
        :title="titleModalBtn"
        :paragraph="paragraphModalBtn"
        btnMsg1="No"
        btnMsg2="Yes"
        :showLoader="isShowLoader"
        @send-result-btn1="redirectToLogin"
        @send-result-btn2="setTokenAndSession"
      />
    </v-main>
  </v-app>
</template>
<style scoped>
#main {
  margin-top: 60px;
}
</style>

<script>
import {
  NoAuthModal,
  CpIscDoubleBtnModal,
  DoubleBtnMixin,
  ExpTokenMixin
} from "@design/styleguide";
export default {
  components: {
    NoAuthModal,
    CpIscDoubleBtnModal,
  },
  mixins: [DoubleBtnMixin, ExpTokenMixin],
  metaInfo() {
    return {
      title: "Isocialcube",
      meta: [
        {
          name: "description",
          content: "Isocialcube",
        },
        {
          property: "og:title",
          content: "Isocialcube",
        },
        { property: "og:site_name", content: "Isocialcube" },
        { property: "og:type", content: "website" },
        { name: "robots", content: "index,follow" },
      ],
    };
  },
};
</script>
