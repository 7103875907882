<template>
  <div class="folder-post">
    <div class="folder-post__item">
      <v-card
        width="220"
        max-height="320"
        class="folder-post__card"
        style="margin-right: 20px"
      >
        <div class="folder-post__lienzo">
          <div class="folder-post__options">
            <cp-dropdown-icon
              margTop="12px"
              margLeft="14px"
              :items="options"
              @selected-action="actionToDo($event, item)"
            />
          </div>
          <img class="folder-post__image" :src="item.image" />
        </div>
        <v-sheet class="folder-post__footer" :min-height="49">
          <div class="card-header-design align-vertical-design">
            {{ item.name }}
          </div>
          <div class="card-date-design align-vertical-design">
            {{ item.createdAt }}
          </div>
        </v-sheet>
      </v-card>
    </div>
    <isc-modal-alert
      v-model="showAlert"
      :title="title"
      :paragraph="paragraph"
      :icon="icon"
      :iconColor="iconColor"
      @clicked="pressBtnOkOrX(item.id)"
      @input="closeIconX(item.id)"
      :showBtn="showBtn"
      :showBtnClose="showBtnClose"
      :showMainIcon="showMainIcon"
    />
  </div>
</template>

<script>
import CpDropdownIcon from "@/components/dropdown/DropDownIcon";
import { mapActions, mapGetters, HandleRespMixin, IscModalAlert } from "@design/styleguide";

export default {
  name: "CardPostFolder",
  components: {
    CpDropdownIcon,
    IscModalAlert
  },
  mixins: [ HandleRespMixin ], 
  props: {
    item: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {
      options: [
        {
          title: "Edit",
          icon: "far fa-edit",
        },
        {
          title: "Clone",
          icon: "far fa-clone",
        },
        {
          title: "Delete",
          icon: "far fa-trash-alt",
        },
      ],
      isWarningConfirmationOk: false
    };
  },
  computed: {
    ...mapGetters({
      isDeletedPost: "post/folder/isDeletePost",
    }),
  },
  methods: {
    ...mapActions({
      clonePost: "post/folder/CLONE_POST",
      deletePost: "post/folder/DELETE_POST",
      setRemovePost: "post/folder/REMOVE_POST_FROM_FOLDER",
      isRemovePost: "post/folder/IS_DELETE_POST",
    }),

    async actionToDo(action, item) {
      switch (action) {
        case "Edit":
          this.$store.dispatch("post/main/LOAD_POST_CANVAS", item);
          this.$store.dispatch("global/saved/ENABLE_EDIT_PROJECT", true);
          this.$router.push("/post");
          break;
        case "Clone":
          this.loadingModal("Please, wait a moment, this post is being cloned.")
          await this.clonePost({id: item.id, responseFunc: this.responseMessageGraphql});
          this.showButtonsModal();
          break;
        case "Delete":
          this.warningModal(`Are you sure that you want to permanently delete this post "${ item.name }"?"`)
          this.isWarningConfirmationOk = true;
          break;
        default:
          console.log("default");
          break;
      }
    },
    //* this validation is from modal warning and error 
    //* if isDeletePost = true then remove form the array.
    async closeIconX(id){
      if(this.isWarningConfirmationOk) this.isWarningConfirmationOk = false;
      else {
       if(this.isDeletedPost) await this.pressBtnOkOrX(id)
      };
    },
    //* this function works from the modal success
    async pressBtnOkOrX(id){
      this.showAlert = false;
      if(this.isWarningConfirmationOk){
        this.loadingModal("Please, wait a moment, post is deleting....")
        await this.deletePost({ id, responseFunc: this.responseMessageGraphql});
        this.showButtonsModal();
        this.isWarningConfirmationOk = false;
        return;
      }
      if(this.isDeletedPost){
        this.setRemovePost(id);
        this.isRemovePost(false);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.folder-post {
  min-height: 304px;
  &__card {
    box-shadow: 0px 2px 6px 2px rgba(44, 56, 82, 0.1) !important;
    border-radius: 6px;

    .h5-title-addon {
      font-size: 15px;
      font-weight: 700;
      line-height: 16px;
    }
    .card-header-design {
      font-family: Oxygen;
      font-size: 15px;
      font-weight: 700;
      line-height: 16px;
      text-overflow: ellipsis;
      word-wrap: break-word;
      overflow: hidden;
      max-height: 16px;
    }
    .card-date-design {
      font-family: Oxygen;
      font-size: 10px;
      font-weight: 400;
      line-height: 16px;
    }
    .align-vertical-design {
      height: 50%;
      display: flex;
      align-items: flex-start;
      margin-bottom: 4px;
    }
    .v-card__text {
      width: 85% !important;
    }
  }
  &__options {
    display: none;
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.2);
  }
  &__item:hover &__options {
    display: block;
  }
  &__image {
    width: 220px;
    height: 209px;
    border-radius: 6px 6px 0px 0px;
    object-fit: contain;
    background-color: #f3f4f7;
  }
  &__lienzo {
    position: relative;
    max-height: 209px;
  }

  &__footer {
    padding-top: 21px;
    padding-left: 18px;
  }
}
</style>
