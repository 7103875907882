import { mapActions, HandleRespMixin  } from "@design/styleguide";

 export const PostMixin = {
  mixins: [ HandleRespMixin ],
  data() {
    return {
      options: [
        {
          title: "Edit",
          icon: "far fa-edit",
        },
        {
          title: "Clone",
          icon: "far fa-clone",
        },
        {
          title: "Delete",
          icon: "far fa-trash-alt",
        },
      ],
      isDeletePost: false,
      item: null
    };
  },
  methods: {
    ...mapActions({
      clonePost: "home/post/CLONE_POST",
      deletePost: "home/post/DELETE_POST",
    }),
     async actionToDo(action, item) {
      switch (action) {
        case "Edit":
          this.$router.push("/post");
          this.$store.dispatch("post/main/LOAD_POST_CANVAS", item);
          this.$store.dispatch("global/saved/ENABLE_EDIT_PROJECT", true);
          break;
        case "Clone":
          this.loadingModal("Please, wait a moment, this post is being cloned")
          await this.clonePost({ id: item.id, folderId: item.folderId, clientId: this.clientId, responseFunc: this.responseMessageGraphql });
          this.showButtonsModal();
          break;
        case "Delete":
          this.warningModal(`Are you sure that you want to permanently delete this post "${ item.name }"?"`)
          this.item = item;
          this.isDeletePost = true;
          break;
        default:
          console.log("default");
          break;
      }
    },
    async resultModalAlerts(value) {
      this.showAlert = value;
      if(this.isDeletePost){
        await this.mainDeletePost();
        this.isDeletePost = false;
      }
    }, 
    async mainDeletePost(){
      this.loadingModal("Please, wait a moment, Post is deleting....")
      await this.deletePost({ id: this.item.id, folderId: this.item.folderId, clientId: this.clientId, responseFunc: this.responseMessageGraphql });
      this.showButtonsModal();
      this.isDeletePost = false;
    },
    closeIconX(){
      this.isDeletePost = false;
    }
  },
};