<template>
  <div class="home-addon">
    <lastest-addons :items="latestUserAddons" />
    <folders-addons
      :folders="folders.data"
      @refresh="
        getFolders();
        getLatestAddons();
      "
    />
    <div class="pagination-footer">
      <div class="container-pagination-footer">
        <v-pagination
          v-model="page"
          :length="getLengthPaginate()"
          :total-visible="7"
          @input="next"
        >
        </v-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "@design/styleguide";
import FoldersAddons from "./FoldersAddons.vue";
import LastestAddons from "./LatestAddons.vue";
const idClientStorage = sessionStorage.getItem("client_id");
const clientId = Number(idClientStorage || process.env.VUE_APP_ID);
export default {
  name: "CarouselAddons",
  components: { FoldersAddons, LastestAddons },
  data() {
    return {
      empty: true,
      page: 1,
      take: 4,
      takeFolder: 4,
      pageFolder: 1,
      limitAddon: 5,
    };
  },
  computed: {
    ...mapGetters({
      latestUserAddons: "home/addon/latestUserAddons",
      folders: "home/addon/foldersWithAddons",
    }),
  },
  mounted() {
    this.getLatestAddons();
    this.getFolders();
  },
  methods: {
    ...mapActions({
      setLatestAddons: "home/addon/FIND_LATEST_ADDONS_BY_TYPE",
      getFolderWithAddon: "home/addon/LIST_ADDONS_WITH_FOLDER",
    }),
    next(page) {
      this.pageFolder = page;
      this.getFolders();
    },
    getFolders() {
      this.getFolderWithAddon({
        take: this.takeFolder,
        page: this.pageFolder,
        limitAddon: this.limitAddon,
        clientId,
      });
    },
    getLatestAddons() {
      this.setLatestAddons({
        take: this.take,
        typeUser: "user",
        clientId,
      });
    },
    getLengthPaginate() {
      return Math.ceil(this.folders.count / this.takeFolder);
    },
  },
};
</script>

<style lang="scss" scoped>
// .home-addon {
//   display: flex;
//   flex-direction: column;
//   align-items: center;
//   margin-top: 95px;
// }
.pagination-footer {
  min-width: 1235px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  padding-right: 87px;
  margin-top: 20px;
  ::v-deep .v-pagination__navigation {
    height: 28px;
    width: 28px;
  }
  ::v-deep .v-pagination__item {
    height: 28px;
    min-width: 28px;
    font-size: 0.9rem;
  }
}

.home-addon {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 63px;
}
@media (max-width: 1300px) {
  .pagination-footer {
    padding-right: 226px;
  }
}

@media (max-width: 1100px) {
  .pagination-footer {
    width: 100%;
    padding-right: 200px;
    .container-pagination-footer{
      width: 21%;
    }
  }
}
</style>
