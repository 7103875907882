export const cardsFolder = {
  props: {
    items: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  data() {
    return { voids: [] };
  },

  watch: {
    items(items) {
      this.cardsByDefault(items);
    },
  },

  methods: {
    cardsByDefault(items) {
      this.voids = [{}, {}, {}, {}, {}];
      if (items.length <= 4) {
        for (let i = 0; i < items.length; i++) {
          this.voids.pop();
        }
      } else {
        this.voids = [];
      }
    },
  },
};
