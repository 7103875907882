import {
  mapActions,
  mapGetters,
  AlertSetting, 
  transformStringDate,
  compareTwoArrays,
  replicateMissingObjects,
} from "@design/styleguide";

export const addons = {
  data() {
    return {
      formatData: [],
      clientId: Number(sessionStorage.getItem("client_id")),
      isDeleteAddon: false,
      item: null
    };
  },
  mixins: [AlertSetting], 
  computed: {
    ...mapGetters({
      leads: "home/addon/leads",
    }),
  },
  methods: {
    ...mapActions({
      cloneAddon: "home/addon/CLONE_ADDON",
      deleteAddon: "home/addon/DELETE_ADDON",
      getLeads: "home/addon/GET_LEADS_BY_ADDON",
      getAddon: "addons/lead/FIND_ADDON_BY_ID",
    }),
    editAddon(item) {
      this.$store.dispatch("addons/create/SET_EDIT_ADDON", item);
      this.$store.dispatch("global/saved/ENABLE_EDIT_PROJECT", true);
      this.$router.push("/addon/create-addon");
    },

    async getQuestionsNames() {
      //console.log(this.formatData);
      const addonId = this.leads[0].addonId;
      const { questions } = await this.getAddon({ id: addonId });
      if (questions.length <= 0) {
        this.errorModal("Data No Found!");
        return;
      }
      // compare into questions array if it has the same ids with this.leads array
      let newLeads = compareTwoArrays(questions, this.leads);
      // remove all empty data array
      newLeads = newLeads.filter((newLead) => newLead.data.length > 0);
      // sample for the first new added questions
      if (newLeads.length <= 0) {
        this.errorModal("Data No Found!");
        return;
      }
      let sampleQuestions = newLeads[0].data;
      // objects which doe not exist in others objects, create all of them (Update) columns
      newLeads = replicateMissingObjects(sampleQuestions, newLeads);
      // create my excel model
      for (const [index, lead] of newLeads.entries()) {
        this.formatData.push({});
        for (const data of lead.data) {
          for (const question of questions) {
            if (data.questionId == question.id) {
              this.formatData[index]["Id"] = lead.id;
              this.formatData[index][question.textQuestion] = data.response;
            }
          }
        }
        this.formatData[index]["Created at"] = transformStringDate(
          lead.createdAt,
          "MM-DD-YYYY"
        );
      }
      // sort it
      this.formatData.sort((a, b) => a.Id - b.Id);
    },

    async actionToDo(action, item, lastest) {
      switch (action) {
        case "Edit":
          this.editAddon(item);
          break;
        case "Clone":
          this.loadingModal("Please, wait a moment, this addon is being cloned")
          //* Setting addon(id) and clientId
          const addon = {
            id: item.id,
            clientId: this.clientId
          }
          await this.cloneAddon({ addon, folderId: item.folderId, clientId: this.clientId, responseFunc: this.responseMessageGraphql  });
          this.showButtonsModal();
          break;
        case "Delete":
          this.warningModal(`Are you sure that you want to permanently delete this addon "${ item.name }"?"`)
          this.item = item;
          this.isDeleteAddon = true;
          break;
        case "Download":
          let element = `to_excel-${item.id}`;
          if (lastest) element = "to_excel";
          await this.getLeads({addonId: item.id, responseFunc: this.responseMessageGraphql} );
          if (this.leads.length <= 0) return;
          await this.getQuestionsNames(this.formatData);
          if (this.formatData.length <= 0) return;
          document.getElementById(element).click();
          this.formatData = [];
          break;
        case "Url":
          window.open(`${process.env.VUE_APP_URL_PREVIEW}/addon/${item.uuid}`);
          break;
        default:
          console.log("default");
          break;
      }
    },
    responseMessageGraphql(msgResp, typeResp ){
      const typeResponse = {
        "error":  () => {
          let modifiedErrMsg = msgResp.replace("GraphQL error:", "")
          return this.errorModal(modifiedErrMsg);
        },
        "success": () => this.successModal(msgResp),
      }      
      return typeResponse[typeResp]();
    },
    async resultModalAlerts(value) {
      this.showAlert = value;
      if(this.isDeleteAddon){
        await this.mainDeleteAddon();
        this.isDeleteAddon = false;
      }
    }, 
    async mainDeleteAddon(){
      this.loadingModal("Please, wait a moment, Addon is deleting....")
      await this.deleteAddon({ id: this.item.id, folderId: this.item.folderId, clientId: this.clientId, responseFunc: this.responseMessageGraphql });
      this.showButtonsModal();
      this.isDeleteAddon = false;
    },
    closeIconX(){
      this.isDeleteAddon = false;
    }
  },
};
