<template>
  <div class="home-post">
    <latest-posts :items="formatItemsPost" />
    <folders-posts
      :folders="formatFolders"
      @refresh="
        getFolders();
        getLatestPosts();
      "
    ></folders-posts>
    <div class="pagination-footer">
      <v-pagination
        v-model="page"
        :length="getLengthPaginate()"
        @input="next"
        :total-visible="7"
      >
      </v-pagination>
    </div>
  </div>
</template>

<script>
import LatestPosts from "./LatestPosts";
import FoldersPosts from "./FoldersPosts";
import { moment, mapGetters, mapActions } from "@design/styleguide";
const idClientStorage = sessionStorage.getItem("client_id");
const clientId = Number(idClientStorage || process.env.VUE_APP_ID);

export default {
  name: "CarouselPost",
  components: { FoldersPosts, LatestPosts },
  data() {
    return {
      empty: true,
      page: 1,
      take: 4,
      lengthPage: 4,
      takeFolder: 5,
      pageFolder: 1,
      limitPost: 5,
    };
  },
  computed: {
    ...mapGetters({
      latesPosts: "home/post/latesPosts",
      foldersWithPosts: "home/post/foldersWithPosts",
    }),
    folders() {
      return this.foldersWithPosts;
    },
    formatItemsPost() {
      return this.latesPosts.map((el) => {
        return {
          id: el.id,
          uuid: el.uuid,
          image: el.finalImage,
          name: el.name,
          folderId: el.folderId,
          createdAt: moment(parseInt(el.createdAt)).format("DD-MM-YYYY"),
          dataObjects: el.dataObjects,
        };
      });
    },
    formatFolders() {
      const folders = this.foldersWithPosts;
      return folders.data.map((el) => {
        return {
          id: el.id,
          name: el.name,
          type: el.type,
          items: el.items.map((p) => {
            return {
              id: p.id,
              uuid: p.uuid,
              image: p.finalImage,
              name: p.name,
              folderId: p.folderId,
              createdAt: moment(parseInt(p.createdAt)).format("DD-MM-YYYY"),
              dataObjects: p.dataObjects,
            };
          }),
        };
      });
    },
  },
  mounted() {
    this.getLatestPosts();
    this.getFolders();
  },
  methods: {
    ...mapActions({
      setLatestPosts: "home/post/FIND_LATEST_POSTS_BY_TYPE",
      getFolderWithPost: "home/post/LIST_POSTS_WITH_FOLDER",
    }),
    next(page) {
      this.pageFolder = page;
      this.getFolders();
    },
    getFolders() {
      this.getFolderWithPost({
        take: this.takeFolder,
        page: this.pageFolder,
        limitPost: this.limitPost,
        clientId,
      });
    },
    getLengthPaginate() {
      return Math.ceil(this.folders.count / this.takeFolder);
    },
    getLatestPosts() {
      this.setLatestPosts({
        take: this.take,
        typeUser: "user",
        clientId,
      });
    },
  },
};
</script>

<style lang="scss">
.home-post {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 63px;
}
.pagination-footer {
  min-width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  // padding-right: 87px;
  margin-top: 20px;
  ::v-deep .v-pagination__navigation {
    height: 28px;
    width: 28px;
  }
  ::v-deep .v-pagination__item {
    height: 28px;
    min-width: 28px;
    font-size: 0.9rem;
  }
}

.car_post {
  position: relative;
  margin-top: 32px;
  box-sizing: content-box !important;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  width: 100%;
  text-decoration: none;
  color: inherit;

  p {
    margin-left: 10px;
  }
  .prev {
    background-color: transparent;
    position: absolute;
    top: 44%;
    left: -25px;
    cursor: pointer;
  }
  .next {
    background-color: transparent;
    position: absolute;
    top: 40%;
    left: -30px;
    cursor: pointer;
  }
}

@media (max-width: 1300px) {
  .home-post{
    width: 100%;
  }

  .pagination-footer{
    padding-right: 80px;
  }
}

@media screen and (max-width: 1023px) {
  .pagination-footer {
    min-width: 1024px;
    padding-right: 30px;
  }
}
</style>
