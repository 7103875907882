<template>
  <div class="nav_module">
    <p>Start <b>managing your own business</b> now!</p>
    <div class="bx_wrapper">
      <router-link style="text-decoration: none; color: inherit" to="/post">
        <div class="bx_post bx_tmpl">
          <div class="img_bx" />
          <h2>Post</h2>
          <span class="number"> 1 </span>
          <p class="texto_in">
            Create a perfect match to achieve your goals.
          </p>
        </div>
      </router-link>
      <router-link style="text-decoration: none; color: inherit" to="/addon">
        <div class="bx_addon bx_tmpl">
          <div class="img_bx" />
          <h2>Addon</h2>
          <span class="number"> 2 </span>
          <p class="texto_in">
            Create a perfect match to achieve your goals.
          </p>
        </div>
      </router-link>
      <router-link style="text-decoration: none; color: inherit" to="/publish">
        <div class="bx_publish bx_tmpl">
          <div class="img_bx" />
          <h2>Publish</h2>
          <span class="number"> 3 </span>
          <p class="texto_in">Publish simultaneously on Social Networks.</p>
        </div>
      </router-link>
    </div>
  </div>
</template>

<script>
export default {
  name: "Modules",
};
</script>

<style lang="scss" >
.nav_module {
  width: 100%;
  height: 334px;
  background: #e5f6ff;

  p {
    text-align: center;
    margin: 0;
    padding-top: 60px;
    font-size: 20px;
  }

  .bx_wrapper {
    margin-top: 32px;
    box-sizing: content-box !important;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    width: 100%;
    text-decoration: none;
    color: inherit;
    .bx_post {
      .img_bx {
        background: #fff url("../../assets/images/post_nav.png") no-repeat
          center;
        background-size: cover;
      }
    }
    .bx_addon {
      margin-left: 20px;
      .img_bx {
        background: #fff url("../../assets/images/addon_nav.png") no-repeat
          center;
        background-size: cover;
      }
    }
    .bx_publish {
      margin-left: 20px;
      .img_bx {
        background: #fff url("../../assets/images/publish_nav.png") no-repeat
          center;
        background-size: cover;
      }
    }
    .bx_tmpl {
      background: #ffffff;
      position: relative;
      //width: 362px;
      max-width: 175.141px;
      padding-left: 186.86px;
      height: 166px;
      box-shadow: 0 2px 6px 2px rgba(44, 56, 82, 0.1);
      border-radius: 6px;
      overflow: hidden;
      cursor: pointer;
      h2 {
        margin-bottom: 10px;
        margin-top: 60px;
        font-size: 18px;
        height: 16px;
        width: 38px;
      }
      .img_bx {
        top: 0;
        left: 0;
        width: 172px;
        max-width: 172px;
        min-height: 166px;
        max-height: 166px;
        background-color: #ccc;
        position: absolute;
        box-shadow: 1.5px 1.3px 3.9px 0.1px rgba(39, 52, 68, 0.15);
        z-index: 2;
      }
      .number {
        position: absolute;
        top: 0;
        right: 20px;
        color: #e5f6ff;
        font-size: 80px;
        padding-bottom: 10px;
      }
      .texto_in {
        margin: 0;
        font-size: 0.625em;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.8;
        letter-spacing: normal;
        color: #5d6b88;
        padding-right: 15px;
        padding-top: 0;
        text-align: left;
      }
    }
  }
}

@media (max-width: 1300px) {
  .nav_module{
    display: flex;
    flex-direction: column;
    align-items: center;

    .bx_wrapper{
      width: 980px;
    }
  }
}

@media (max-width: 1100px) {
  .bx_wrapper{
      width: 900px !important;
    }
}

@media screen and (max-width: 1024px) {
  .bx_tmpl{
    max-width: 130px !important;
  }
}
</style>
