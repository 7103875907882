<template>
  <div class="folder" style="padding-left: 7px;">
    <div
      style="margin-top: 50px"
      v-for="folder in folders"
      :key="'folder' + folder.id"
    >
      <v-row style="padding-bottom: 15px; padding-left: 0px; padding-right: 0px;">
        <v-col cols="12" class="pb-0 pt-0">
          <div class="folder__title">
            <div class="folder__name" v-if="!getEdit(folder.id)">
              <p class="folder__name--style main-subtitle">{{ folder.name }}</p>
              <div class="folder__button">
                <drop-down-icon
                  :items="options"
                  classBackgroundColor="style-black"
                  @selected-action="actionToDo($event, folder)"
                />
              </div>
            </div>
            <div class="folder__edit" v-else>
              <div class="folder__edit--base">
                <base-input v-model="folder.nameEdit" />
              </div>
              <v-btn
                class="folder__btn-save"
                fab
                dark
                small
                color="primary"
                @click="rename(folder)"
              >
                <v-icon dark> mdi-content-save </v-icon>
              </v-btn>
              <v-btn
                class="mx-2"
                fab
                dark
                small
                color="error"
                @click="cancel(folder)"
              >
                <v-icon dark> mdi-cancel </v-icon>
              </v-btn>
            </div>
            <div class="folder__view">
              <v-btn class="folder__view--btn" plain @click="viewAll(folder)">
                View all
              </v-btn>
            </div>
          </div>
        </v-col>
      </v-row>
      <v-sheet max-width="1235">
        <posts-cards :show-arrows="showArrows" :items="folder.items" />
      </v-sheet>
    </div>
    <isc-modal-alert
      v-model="showAlert"
      :title="title"
      :paragraph="paragraph"
      :icon="icon"
      :iconColor="iconColor"
      @clicked="resultModalAlerts"
      :showBtn="showBtn"
      :showBtnClose="showBtnClose"
      :showMainIcon="showMainIcon"
      @input="closeIconX"
    />
  </div>
</template>

<script>
import PostsCards from "@/components/folder/post/PostsCards";
import DropDownIcon from "@/components/dropdown/DropDownIcon";
import { BaseInput, mapActions, IscModalAlert, HandleRespMixin } from "@design/styleguide";

export default {
  name: "FoldersPosts",
  components: {
    BaseInput,
    DropDownIcon,
    PostsCards,
    IscModalAlert
  },
  mixins: [ HandleRespMixin ],
  props: {
    folders: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  data() {
    return {
      options: [
        {
          title: "Rename",
          icon: "far fa-edit",
        },
        {
          title: "Clone",
          icon: "far fa-clone",
        },
        {
          title: "Delete",
          icon: "far fa-trash-alt",
        },
      ],
      edit: {},
      showArrows: false,
      idFolder: 0,
      isDeleteFolder: false 
    };
  },
  mounted() {
    let width = document.documentElement.clientWidth;
    if(width <= 1300 ) this.showArrows = true;
  },
  methods: {
    ...mapActions({
      renameFolder: "home/post/RENAME_FOLDER",
      cloneFolder: "home/post/CLONE_FOLDER",
      deleteFolder: "home/post/DELETE_FOLDER",
    }),
    viewAll(item) {
      this.$router.push(`/main/post/folder/${item.id}`);
    },
    async actionToDo(action, item) {
      switch (action) {
        case "Rename":
          item.nameEdit = item.name;
          this.edit = { ...this.edit, [`edit${item.id}`]: true };
          break;
        case "Clone":
          this.loadingModal("Please, wait a moment, this folder is being cloned")
          const folderContext = {
            id : item.id,
            clientId: 0,// default to 0 because post doesn't have a plan
            items: item?.items.length ?? 0,
            type: item.type
          }
          await this.cloneFolder({ folderContext, responseFunc: this.responseMessageGraphql });
          this.showButtonsModal();
          this.$emit("refresh");
          break;
        case "Delete":
          this.warningModal(`Are you sure that you want to permanently delete this folder "${ item.name }"?"`)
          this.idFolder = item.id;
          this.isDeleteFolder = true;
          break;
        default:
          console.log("default");
          break;
      }
    },
    getEdit(id) {
      return this.edit[`edit${id}`];
    },
    cancel(item) {
      this.edit = { ...this.edit, [`edit${item.id}`]: false };
    },
    async rename(item) {
      await this.renameFolder({
        id: item.id,
        folder: { name: item.nameEdit, type: item.type },
        responseFunc: this.responseMessageGraphql
      });
      this.edit = { ...this.edit, [`edit${item.id}`]: false };
    },
    async resultModalAlerts(value) {
      this.showAlert = value;
      if(this.isDeleteFolder){
        await this.mainDeleteFolder();
        this.isDeleteFolder = false;
      }
    },
    async mainDeleteFolder() {
      this.loadingModal("Please, wait a moment, this folder is being deleted")
      await this.deleteFolder({id: this.idFolder, responseFunc: this.responseMessageGraphql});
      this.showButtonsModal();
      this.isDeleteFolder = false;
      this.$emit("refresh");
    },
    closeIconX(){
      this.isDeleteFolder = false;
    }
  },
};
</script>

<style lang="scss" scoped>
.folder {
  margin: 0 !important;
  &__name {
    display: flex;
    font-weight: 700;
    &--style {
      font-size: 16px;
      margin-bottom: 2px;
      margin-top: 5px;
    }
  }
  &__title {
    display: flex;
    flex-direction: row;
    margin-bottom: 11px;
    max-height: 34px;
  }
  &__button {
    margin-left: 21px;
    margin-bottom: 20px;
  }
  &__view {
    flex: 1;
    text-align: end;
    padding-right: 31px;
    align-self: center;
    &--btn {
      ::v-deep .v-btn__content {
        color: #96a0b8;
        font-weight: 400;
        font-size: 15px;
      }
    }
  }
  &__edit {
    display: flex;
    &--base {
      width: 50%;
    }
  }
  &__btn-save {
    margin-left: 35px;
  }
}

@media (max-width: 1300px){
  .folder{
    width: 100%;
  }
}
</style>
