<template>
  <div>
    <v-card
      width="209"
      min-height="281"
      class="folder__card"
      style="margin-right: 20px"
    >
      <div class="folder__item">
        <div class="folder__lienzo">
          <div class="folder__options">
            <cp-dropdown-icon
              margTop="12px"
              margLeft="14px"
              :items="options"
              @selected-action="actionToDo($event, item)"
            />
          </div>
          <img class="folder__image" :src="item.finalImage" />
        </div>
      </div>
  
      <v-sheet class="folder__footer" :min-height="49">
        <div class="folder__footer--name">
          {{ item.name }}
        </div>
        <div class="folder__footer--date">
          {{ getDate(item.createdAt) }}
        </div>
      </v-sheet>
      <json-excel
        :data="formatLeads"
        :id="'to_excel-' + item.id"
        :name="nameExcel"
        style="display: none"
      >
        Download Data
      </json-excel>
    </v-card>
    <isc-modal-alert
      v-model="showAlert"
      :title="title"
      :paragraph="paragraph"
      :icon="icon"
      :iconColor="iconColor"
      @clicked="pressBtnOkOrX(item.id)"
      :showBtn="showBtn"
      :showBtnClose="showBtnClose"
      :showMainIcon="showMainIcon"
      @input="closeIconX(item.id)"
    />
  </div>
</template>

<script>
import CpDropdownIcon from "@/components/dropdown/DropDownIcon";
import {
  transformStringDate,
  compareTwoArrays,
  replicateMissingObjects,
  moment,
  mapActions,
  mapGetters,
  IscModalAlert,
  HandleRespMixin
} from "@design/styleguide";

import JsonExcel from "vue-json-excel";

export default {
  name: "card-folder",
  components: {
    CpDropdownIcon,
    JsonExcel,
    IscModalAlert,
  },
  //* Important: various methods of modal errors come from here (HandleRespMixin).
  mixins: [HandleRespMixin],
  props: {
    item: {
      type: Object,
      default: () => {
        return {};
      },
    },
    value: {
      type: [String, Number],
      default: "",
    },
  },
  data: () => ({
    nameExcel: "data-leads.xls",
    options: [
      {
        title: "Edit",
        icon: "far fa-edit",
      },
      {
        title: "Clone",
        icon: "far fa-clone",
      },
      {
        title: "Url",
        icon: "fa fa-eye",
      },
      {
        title: "Download",
        icon: "fa fa-download",
      },
      {
        title: "Delete",
        icon: "far fa-trash-alt",
      },
    ],
    formatLeads: [],
    isWarningConfirmationOk: false
  }),
  computed: {
    ...mapGetters({
      leads: "home/addon/leads",
      isDeletedAddon: "addons/folders/isDeleteAddon",
    }),
  },
  methods: {
    ...mapActions({
      cloneAddon: "addons/folders/CLONE_ADDON",
      deleteAddon: "addons/folders/DELETE_ADDON",
      setRemoveAddon: "addons/folders/REMOVE_ADDON_FROM_FOLDER",
      isRemovedAddon: "addons/folders/IS_DELETE_ADDON",
      getLeads: "home/addon/GET_LEADS_BY_ADDON",
      getAddon: "addons/lead/FIND_ADDON_BY_ID",
    }),
    async actionToDo(event, item) {
      switch (event) {
        case "Edit":
          this.editAddon(item);
          break;
        case "Clone":
          this.loadingModal("Please, wait a moment, this addon is being cloned")
          //* Setting addon(id) and clientId
          const addon = {
            id: item.id,
            clientId: this.clientId
          }
          await this.cloneAddon({addon, responseFunc: this.responseMessageGraphql});
          this.showButtonsModal();
          break;
        case "Download":
          await this.getLeads({addonId: item.id, responseFunc: this.responseMessageGraphql});
          if (this.leads.length <= 0) return;
          await this.getQuestionsNames();
          if (this.formatLeads.length <= 0) return;
          document.getElementById(`to_excel-${item.id}`).click();
          this.formatLeads = [];
          break;
        case "Url":
          window.open(`${process.env.VUE_APP_URL_PREVIEW}/addon/${item.uuid}`);
          break;
        case "Delete":
          this.warningModal(`Are you sure that you want to permanently delete this addon "${ item.name }"?"`)
          this.isWarningConfirmationOk = true;
          break;
        default:
          console.log("default");
          break;
      }
    },
    //* this validation is from modal warning and error 
    //* if isDeleteAddon = true then remove form the array.
    async closeIconX(id){
      if(this.isWarningConfirmationOk) this.isWarningConfirmationOk = false;
      else {
       if(this.isDeletedAddon) await this.pressBtnOkOrX(id)
      };
    },
    //* this function works from the modal success
    async pressBtnOkOrX(id){
      this.showAlert = false;
      if(this.isWarningConfirmationOk){
        this.loadingModal("Please, wait a moment, Addon is deleting....")
        await this.deleteAddon({ id, responseFunc: this.responseMessageGraphql});
        this.showButtonsModal();
        this.isWarningConfirmationOk = false;
        return;
      }
      if(this.isDeletedAddon){
        this.setRemoveAddon(id);
        this.isRemovedAddon(false);
      }
    },
    getDate(item) {
      return moment(parseInt(item)).format("DD-MM-YYYY");
    },
    editAddon(item) {
      this.$store.dispatch("addons/create/SET_EDIT_ADDON", item);
      this.$store.dispatch("global/saved/ENABLE_EDIT_PROJECT", true);
      this.$router.push("/addon/create-addon");
    },
    async getQuestionsNames() {
      const addonId = this.leads[0].addonId;
      const { questions } = await this.getAddon({ id: addonId });
      if (questions.length <= 0) {
        this.errorModal("Data No Found!");
        return;
      }
      // compare into questions array if it has the same ids with this.leads array
      let newLeads = compareTwoArrays(questions, this.leads);
      // remove all empty data array
      newLeads = newLeads.filter((newLead) => newLead.data.length > 0);
      // sample for the first new added questions
      if (newLeads.length <= 0) {
        this.errorModal("Data No Found!");
        return;
      }
      let sampleQuestions = newLeads[0].data;
      // objects which doe not exist in others objects, create all of them (Update) columns
      newLeads = replicateMissingObjects(sampleQuestions, newLeads);
      // create my excel model
      for (const [index, lead] of newLeads.entries()) {
        this.formatLeads.push({});
        for (const data of lead.data) {
          for (const question of questions) {
            if (data.questionId == question.id) {
              this.formatLeads[index]["Id"] = lead.id;
              this.formatLeads[index][question.textQuestion] = data.response;
            }
          }
        }
        this.formatLeads[index]["Created at"] = transformStringDate(
          lead.createdAt,
          "MM-DD-YYYY"
        );
      }
      // sort it
      this.formatLeads.sort((a, b) => a.Id - b.Id);
    },
  },
};
</script>
<style scoped lang="scss">
.folder {
  min-height: 304px;
  &__card {
    box-shadow: 0px 2px 6px 2px rgba(44, 56, 82, 0.1) !important;
    border-radius: 6px;
  }
  &__options {
    display: none;
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.2);
  }
  &__item:hover &__options {
    display: block;
  }
  &__image {
    width: 209px;
    height: 209px;
    border-radius: 6px 6px 0px 0px;
    object-fit: contain;
    background-color: #f3f4f7;
  }
  &__lienzo {
    position: relative;
    max-height: 209px;
  }
  &__question {
    font-family: "Oxygen";
    font-style: normal;
    font-weight: 700;
    font-size: 7px;
    line-height: 20px;
    border: 1px solid #c5d0e4;
    box-sizing: border-box;
    margin-right: 10px;
  }
  &__footer {
    padding-top: 21px;
    padding-left: 18px;
    &--name {
      text-align: left;
      font-size: 15px;
      line-height: 16px;
      font-weight: bold;
      margin-bottom: 4px;
    }
    &--date {
      font-weight: normal;
      font-size: 10px;
      line-height: 16px;
      text-align: left;
    }
  }
}
</style>
