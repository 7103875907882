<template>
  <div class="latest-addons">
    <v-row>
      <v-col>
        <p class="main-subtitle">Latest</p>
      </v-col>
      <v-col class="latest-addons__folder">
        <!-- <router-link style="text-decoration: none; color: inherit" to="/addon"> -->
        <div class="latest-addons__btnfolder" @click="addFolder">
          <div style="display: inline">
            <i class="fa fa-plus" aria-hidden="true"></i>
          </div>
          <p class="btn-add mb-0">ADD A FOLDER</p>
        </div>
        <!-- </router-link> -->
        <AddFolderModal
          @trigger-success="setSuccessMessage"
          @trigger-error="setErrorMessage" 
          :clientId="clientId" 
          type="addon" 
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col class="cards__items">
        <template v-if="isLoading.latestUserAddons">
          <v-skeleton-loader
            v-for="i in 5"
            :key="i"
            class="latest-addons__item"
            elevation="1"
            type="image, list-item-three-line"
          ></v-skeleton-loader>
        </template>
        <template v-else>
          <div class="latest-addons__item" v-for="item in items" :key="item.id">
            <div class="latest-addons__lienzo">
              <div class="latest-addons__options">
                <drop-down-icon
                  margTop="12px"
                  margLeft="14px"
                  :items="options"
                  @selected-action="actionToDo($event, item, true)"
                />
              </div>
              <img class="latest-addons__image" :src="item.finalImage" />
            </div>
            <v-sheet class="latest-addons__card-footer" :min-height="49">
              <div class="latest-addons__name">{{ item.name }}</div>
              <div class="latest-addons__date">
                {{ getStringDate(item.createdAt) }}
              </div>
            </v-sheet>
          </div>
          <router-link style="text-decoration: none; color: inherit" to="/addon">
            <div class="new_item_crsl">
              <div class="ico">
                <i class="fa fa-plus" aria-hidden="true"></i>
              </div>
            </div>
          </router-link>
        </template>

        <!-- <div class="void_item"  v-for="(item, index) in voids" :key="index"></div> -->
      </v-col>
    </v-row>
    <json-excel-home
      :data="formatData"
      id="to_excel"
      :name="nameExcel"
      style="display: none"
    >
      Download Data
    </json-excel-home>
    <isc-modal-alert
      v-model="showAlert"
      :title="title"
      :paragraph="paragraph"
      :icon="icon"
      :iconColor="iconColor"
      @clicked="resultModalAlerts"
      @input="closeIconX"
      :showBtn="showBtn"
      :showBtnClose="showBtnClose"
      :showMainIcon="showMainIcon"
    />
  </div>
</template>

<script>
import DropDownIcon from "@/components/dropdown/DropDownIcon";
import {
  transformStringDate,
  AddFolderModal,
  IscModalAlert,
  mapGetters
} from "@design/styleguide";
import JsonExcelHome from "vue-json-excel";
import { addons } from "@/mixins/addons";

export default {
  name: "LastestAddons",
  mixins: [addons],
  components: {
    DropDownIcon,
    JsonExcelHome,
    AddFolderModal,
    IscModalAlert,
  },
  props: {
    items: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  computed: {
    ...mapGetters({
      isLoading: "home/addon/isLoading",
    }),
  },
  data() {
    return {
      empty: true,
      hoverLatest: false,
      isActive: 0,
      activeOptions: 0,
      nameExcel: "data-leads.xls",
      options: [
        {
          title: "Edit",
          icon: "far fa-edit",
        },
        {
          title: "Clone",
          icon: "far fa-clone",
        },
        {
          title: "Url",
          icon: "fa fa-eye",
        },
        {
          title: "Download",
          icon: "fa fa-download",
        },

        {
          title: "Delete",
          icon: "far fa-trash-alt",
        },
      ],
      json_fields: {
        "Addon's name": "name",
        Title: "title",
        Description: "description",
        "Confirmation message": "message",
      },
      voids: [],
      actionAddon: false,
      formatData: [],
    };
  },
  watch: {
    items(items) {
      this.voids = [{}, {}, {}, {}];
      if (items.length <= 3) {
        for (let i = 0; i < items.length; i++) {
          this.voids.pop();
        }
      } else {
        this.voids = [];
      }
    },
  },
  mounted() {},
  methods: {
    getStringDate(date) {
      return transformStringDate(date, "MM-DD-YYYY");
    },
    addFolder() {
      this.$store.dispatch("modals/open", "addFolder");
    },
    //** ALERTS MODAL */
    setSuccessMessage(msg) {
      //* this function comes from PostMixin
      //* which contains HandleRespMixin 
      this.successModal(msg);
    },
    setErrorMessage(msg){
      //* this function comes from PostMixin 
      //* which contains HandleRespMixin 
      this.errorModal(msg);
    }
  },
  destroyed() {
    this.actionAddon = false;
  },
};
</script>
<style lang="scss" scoped>
.latest-addons {
  padding-right: 5px;
  width: 100%;
  // .row2{
  //   margin: 0;
  // }
  &__item {
    position: relative;
    width: 207px;
    height: 281px;
    float: left;
    //text-align: center;
    margin: 0px 20px 0px 0px;
    border-radius: 6px;
    background: #ffffff;
    box-shadow: 0px 2px 6px 2px rgba(44, 56, 82, 0.1);
    border-radius: 6px;
  }
  &__image {
    width: 209px;
    height: 209px;
    border-radius: 6px 6px 0px 0px;
    object-fit: contain;
    background-color: #f3f4f7;
  }
  &__logo {
    font-family: Montserrat;
    font-size: 12px !important;
    font-weight: 900 !important;
    line-height: 20px !important;
    color: #ea9a41;
    text-align: center;
    margin-bottom: 5px;
  }
  &__title {
    font-family: Oxygen;
    font-size: 8px;
    font-weight: 700;
    line-height: 20px;
    color: black;
    text-align: center;
  }
  &__description {
    font-family: Oxygen;
    font-size: 6px;
    font-weight: 400;
    line-height: 20px;
    text-align: center;
  }
  &__question {
    font-family: "Oxygen";
    font-style: normal;
    font-weight: 700;
    font-size: 7px;
    line-height: 20px;
    border: 1px solid #c5d0e4;
    box-sizing: border-box;
    margin: 0px 10px;
  }
  &__item:hover &__options {
    display: block;
  }
  &__name {
    text-align: left;
    font-size: 15px;
    line-height: 16px;
    font-weight: bold;
    margin-bottom: 4px;
  }
  &__date {
    font-weight: normal;
    font-size: 10px;
    line-height: 16px;
    text-align: left;
  }
  &__options {
    display: none;
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.2);
  }
  &__lienzo {
    position: relative;
  }
  &__folder {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    color: #c5d0e4 !important;
  }
  &__card-footer {
    padding-top: 21px;
    padding-left: 18px;
  }
  // button "ADD FOLDER"
  &__btnfolder {
    cursor: pointer;
    &:hover {
      color: #2c3852;
    }
    .btn-add {
      display: inline;
      font-family: "Oxygen";
      font-style: normal;
      font-weight: 700;
      font-size: 15px;
      margin-left: 20px;
    }
  }
  .new_item_crsl {
    position: relative;
    width: 209px;
    height: 281px;
    background: #f3f4f7;
    float: left;
    text-align: center;
    border: 1px solid #f3f4f7;
    box-shadow: 0px 2px 6px 2px rgba(44, 56, 82, 0.1);
    border-radius: 6px;
    .ico {
      cursor: pointer;
      padding-top: 100px;
      color: #c5d0e4;
      font-size: 50px;
    }
  }
  .void_item {
    position: relative;
    width: 209px;
    height: 281px;
    background: #ffffff;
    float: left;
    text-align: center;
    margin: 0px 20px 0px 0px;
    opacity: 0.5;
    box-shadow: 0px 2px 6px 2px rgba(44, 56, 82, 0.1);
    border-radius: 6px;
  }
  .cards__items {
    display: flex;
    width: 80%;
    // padding: 0;
  }
}

@media (max-width: 1300px) {
  .cards__items {
    overflow-x: scroll;
    overflow-y: hidden;
  }
  .latest-addons {
    width: 975px;
  }
  .latest-addons .latest-addons__item {
    width: 201px;
  }

  .latest-addons .latest-addons__image {
    width: 201px;
  }

  .latest-addons .row:nth-child(2) {
    width: 100%;
  }
}
@media (max-width: 1100px) {
  .latest-addons {
    width: 900px;
  }
}
@media screen and (max-width: 1024px) {
  .void_item {
    &:last-child {
      margin-right: 0 !important;
    }
  }
  .new_item_crsl {
    margin-right: 20px;
  }
  .new_item_crsl,
  .void_item {
    width: 179px !important;
    height: 251px !important;
  }
}
</style>
