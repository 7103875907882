<template>
  <div class="navDesigns">
    <div class="tab_headers">
      <span class="myPost" :class="{ active: dPost }" @click="showPosts"
        >My Post</span
      >
      <span class="myAddons" :class="{ active: dAddon }" @click="showAddons"
        >My Addons</span
      >
    </div>
    <div class="sliders-container">
      <carousel-post v-if="dPost" />
      <carousel-addon v-if="dAddon" />
    </div>
  </div>
</template>

<script>
import CarouselPost from "./post/Carousel";
import CarouselAddon from "./addon/Carousel";
export default {
  name: "Folder",
  components: { CarouselPost, CarouselAddon },
  data: () => ({
    dPost: true,
    dAddon: false,
  }),
  methods: {
    showPosts() {
      this.dPost = true;
      this.dAddon = false;
    },
    showAddons() {
      this.dPost = false;
      this.dAddon = true;
    },
  },
};
</script>

<style lang="scss" scoped>
.navDesigns {
  padding: 40px 0 40px 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  .tab_headers {
    flex-direction: row;
    text-align: center;
    font-size: 20px;
    line-height: 16px;
    span {
      cursor: pointer;
      font-weight: bold;
      color: #a7a7a7;
    }
    span.active {
      color: #000;
      font-weight: bold;
    }
    .myPost {
      margin-right: 35px;
    }
    .myAddon {
      margin-left: 35px;
    }
  }
  .sliders-container {
    margin-top: 32px;
    box-sizing: content-box !important;
    max-width: 1125px;
    width: 1125px;
    /* display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  width: 100%;
  text-decoration: none;
  color: inherit; */
  }
}

@media (max-width: 1300px) {
  .sliders-container {
    max-width: 1100px !important;
    width: 1100px !important;
  }
}

@media (max-width: 1100px) {
  .sliders-container {
    max-width: 1000px !important;
    width: 1000px !important;
  }
}
</style>
