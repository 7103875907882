<template>
  <div style="height: 100%;">
    <div v-if="isScreensizeLess" class="d-flex justify-center align-center" style="height: 100%; background-color: #00a5ff;">
      <screensize-modal/>
    </div>
    <div class="folder" v-on:scroll="handleScroll" ref="container" v-if="!isScreensizeLess">
      <div style="margin-top: 50px">
        <v-row style="margin: 50px">
          <v-col class="pb-0 pt-0 col1-folder-post">
            <v-row style="padding-left: 0 !important">
              <v-btn
                icon
                style="display: flex; align-items: flex-end"
                @click="prev"
              >
                <v-icon style="font-size: 40px; color: black"
                  >mdi-chevron-left</v-icon
                >
              </v-btn>

              <div class="folder__title">
                <div class="folder__name">
                  <p class="folder__name--style" style="font-size: 25px">
                    {{ folder.name }}
                  </p>
                </div>
              </div>
            </v-row>
          </v-col>
          <v-col cols="3" class="col2-folder-post">
            <v-row justify="end">
              <span class="mr-3" style="align-items: center; display: flex">
                Sort by:
              </span>

              <select-input
                v-model="order"
                :items="options"
                @input="change()"
                text="name"
                id="id"
              />
            </v-row>
          </v-col>
        </v-row>
        <div class="parent" ref="parentItems">
          <div class="child" v-for="item in postsFormat" :key="item.id">
            <card-post-folder :item="item" />
          </div>

          <template v-if="isLoading">
              <div class="child-loading" v-for="i in 10">
                <v-skeleton-loader
                  class="mr-5" 
                  width="209"
                  elevation="1"
                  type="image, list-item-three-line"
                ></v-skeleton-loader>
              </div>
          </template>
        </div>
        <div v-if="!morePost" class="message">
          There are no more items to show
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CardPostFolder from "@/components/folder/post/CardPostFolder.vue";
import {
  mapActions,
  mapGetters,
  moment,
  SelectInput,
  ScreensizeModal,
} from "@design/styleguide";
export default {
  components: { SelectInput, CardPostFolder, ScreensizeModal },
  data() {
    return {
      options: [
        {
          name: "Last modified",
          id: "updatedAt",
        },
        {
          name: "Alphabetically",
          id: "name",
        },
      ],
      page: 1,
      take: 10,
      order: "name",
      isScreensizeLess: false,
    };
  },
  computed: {
    ...mapGetters({
      posts: "post/folder/postsByFolder",
      isLoading: 'post/folder/isLoading',
      folder: "post/folder/folderView",
      morePost: "post/folder/morePost",
    }),
    postsFormat() {
      return this.posts.map((el) => {
        return {
          id: el.id,
          image: el.finalImage,
          name: el.name,
          folderId: el.folderId,
          createdAt: moment(parseInt(el.createdAt)).format("DD-MM-YYYY"),
          dataObjects: el.dataObjects,
        };
      });
    },
  },
  mounted() {

    window.addEventListener("scroll", this.onScroll);
  },
  methods: {
    ...mapActions({
      getPostsByFolder: "post/folder/FIND_POSTS_BY_FOLDER_PAGINATE_ORDER",
      getFolder: "post/folder/GET_FOLDER",
    }),
    onScroll() {
      const refParentItems = this.$refs.parentItems
      const lastChild = refParentItems.querySelector('.child:last-of-type')

      if(lastChild) { 
        const observer = new IntersectionObserver(
          (entries) => {
            entries.forEach((entry) => {
              if (entry.isIntersecting && !this.loadingScroll && this.morePost){ 
                  this.page++
                  this.getPosts();
                  this.loadingScroll = true;
              }
            });
          },
          {
            root: null,
            threshold: 1.0, 
          }
        );
        observer.observe(lastChild);
  
        this.loadingScroll = false;
      }

    },
    change() {
      this.page = 1;
      this.getPosts();
    },
    prev() {
      this.$router.push("/main");
    },
    getPosts() {
      this.getPostsByFolder({
        folderId: parseInt(this.$route.params.id),
        page: this.page,
        take: this.take,
        order: this.order,
      });
    },
    handleScroll() { 
      const { scrollHeight, scrollTop, clientHeight } = this.$refs.container;
      const entireHeight = Math.round(scrollTop + clientHeight);
      this.loadingScroll = false;
      if (
        entireHeight >= scrollHeight &&
        !this.loadingScroll &&
        this.morePost
      ) {
        this.page += 1;
        this.getPosts();
        this.loadingScroll = true;
      }
    },
    getDimensions() {
      this.width = document.documentElement.clientWidth;
      (this.width < 1024) ? this.isScreensizeLess = true : this.isScreensizeLess = false;
    },
  },
  created() {
    this.getPosts();
    this.getFolder(parseInt(this.$route.params.id));
    this.getDimensions();
    window.addEventListener("resize", this.getDimensions);    
  },
  destroyed() {
    window.removeEventListener("scroll", this.onScroll);
  }
};
</script>

<style scoped lang="scss">
.folder {
  margin: 0 !important;
  overflow-y: auto;
  max-height: 100%;
  margin-top: 50px;
  //margin-bottom: 25px;
  //max-width: min-content;
  &__name {
    display: flex;
    font-weight: 900;
    font-size: 25px;
    &--style {
      margin-bottom: 2px;
      margin-top: 5px;
    }
  }
  &__title {
    display: flex;
    flex-direction: row;
    // padding-left: 55px;
    margin-bottom: 11px;
    max-height: 34px;
  }
  &__button {
    margin-left: 21px;
    margin-bottom: 20px;
  }
  &__view {
    flex: 1;
    text-align: end;
    padding-right: 87px;
    align-self: center;
    &--btn {
      ::v-deep .v-btn__content {
        color: #96a0b8;
        font-weight: 400;
        font-size: 15px;
      }
    }
  }
}
.parent {
  display: flex;
  flex-wrap: wrap;
  padding-left: 55px;
  margin: 50px;
}

.child, .child-loading {
  list-style: none;
  flex: 0 0 20%;
  margin-bottom: 50px;
}
.message {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 20px;
  top: -30px;
  position: relative;
  color: #7a7c7f;
  font-size: 29px;
  text-align: center;
  text-shadow: 0 1px 1px #fff;
}

@media (max-width: 1300px) {
  .col2-folder-post {
    margin-right: 40px;
  }
  .col2-folder-post div {
    padding: 0 !important;
  }
}
</style>
