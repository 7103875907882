<template>
  <div class="latest-posts">
    <v-row>
      <v-col>
        <p class="main-subtitle">Latest</p>
      </v-col>
      <v-col class="latest-posts__folder">
        <!-- <router-link style="text-decoration: none; color: inherit" to="/post"> -->
        <div class="latest-posts__btnfolder" @click="addFolder">
          <div style="display: inline">
            <i class="fa fa-plus" aria-hidden="true"></i>
          </div>
          <p class="btn-add mb-0">ADD A FOLDER</p>
        </div>
        <!-- </router-link> -->
        <AddFolderModal
          @trigger-success="setSuccessMessage"
          @trigger-error="setErrorMessage"
          :clientId="clientId"
          type="post"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col class="cards__items"> 
        <template v-if="isLoading.latestPosts">
          <v-skeleton-loader
            v-for="i in 5"
            :key="i"
            class="latest-posts__item"
            elevation="1"
            type="image, list-item-three-line"
          ></v-skeleton-loader>
        </template>
        <template v-else>
          <div class="latest-posts__item" v-for="item in items" :key="item.id">
            <div class="latest-posts__lienzo">
              <div class="latest-posts__options">
                <drop-down-icon
                  margTop="12px"
                  margLeft="14px"
                  :items="options"
                  @selected-action="actionToDo($event, item)"
                />
              </div>
              <img class="latest-posts__image" :src="item.image" />
            </div>
            <v-sheet class="latest-posts__card-footer" :min-height="49">
              <div class="latest-posts__name">{{ item.name }}</div>
              <div class="latest-posts__date">{{ item.createdAt }}</div>
            </v-sheet>
          </div>

          <router-link style="text-decoration: none; color: inherit" to="/post">
            <div class="new_item_crsl">
              <div class="ico">
                <i class="fa fa-plus" aria-hidden="true"></i>
              </div>
            </div>
          </router-link>
        </template>
        <!-- <div class="void_item" v-for="item in items" :key="item.id"></div> -->
      </v-col>
    </v-row>
    <isc-modal-alert
      v-model="showAlert"
      :title="title"
      :paragraph="paragraph"
      :icon="icon"
      :iconColor="iconColor"
      @clicked="resultModalAlerts"
      :showBtn="showBtn"
      :showBtnClose="showBtnClose"
      :showMainIcon="showMainIcon"
      @input="closeIconX"
    />
  </div>
</template>

<script>
import DropDownIcon from "@/components/dropdown/DropDownIcon";
import { IscModalAlert, AddFolderModal, mapGetters } from "@design/styleguide";
import { PostMixin } from "@/mixins/postMixin";
export default {
  name: "LastestPosts",
  components: {
    DropDownIcon,
    AddFolderModal,
    IscModalAlert,
  },
  mixins: [PostMixin],
  props: {
    items: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  computed: {
    ...mapGetters({
      isLoading: "home/post/isLoading",
    }),
  },
  data() {
    return {
      empty: true,
      hoverLatest: false,
      isActive: 0,
      activeOptions: 0,
      openAddFolderModal: false,
      voids: [],
    };
  },
  watch: {
    items(items) {
      this.voids = [{}, {}, {}, {}];
      if (items.length <= 3) {
        for (let i = 0; i < items.length; i++) {
          this.voids.pop();
        }
      } else {
        this.voids = [];
      }
    },
  },
  methods: {
    addFolder() {
      this.$store.dispatch("modals/open", "addFolder");
    },
    //** ALERTS MODAL */
    setSuccessMessage(msg) {
      //* this function comes from PostMixin
      //* which contains HandleRespMixin 
      this.successModal(msg);
    },
    setErrorMessage(msg){
      //* this function comes from PostMixin 
      //* which contains HandleRespMixin 
      this.errorModal(msg);
    }
  },
};
</script>
<style lang="scss" scoped>
.cards__items {
  display: flex;
  gap: 20px;
}
.latest-posts {
  width: 100%;
  p {
    font-size: inherit;
  }
  padding-right: 5px;
  &__item {
    position: relative;
    width: 209px;
    float: left;
    //text-align: center;
    margin: 0;
    border-radius: 6px;
    background: #ffffff;
    box-shadow: 0px 2px 6px 2px rgba(44, 56, 82, 0.1);
    border-radius: 6px;
  }
  &__image {
    width: 209px;
    height: 209px;
    border-radius: 6px 6px 0px 0px;
    object-fit: contain;
    background-color: #f3f4f7;
  }
  &__item:hover &__options {
    display: block;
  }
  &__name {
    text-align: left;
    font-size: 15px;
    line-height: 16px;
    font-weight: bold;
    margin-bottom: 4px;
    text-overflow: ellipsis;
    word-wrap: break-word;
    overflow: hidden;
    max-height: 16px;
  }
  &__date {
    font-weight: normal;
    font-size: 10px;
    line-height: 16px;
    text-align: left;
  }
  &__options {
    display: none;
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.2);
  }
  &__lienzo {
    position: relative;
  }
  &__folder {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    color: #c5d0e4 !important;
  }
  &__card-footer {
    padding-top: 21px;
    padding-left: 18px;
  }
  // button "ADD FOLDER"
  &__btnfolder {
    cursor: pointer;
    &:hover {
      color: #2c3852;
    }
    .btn-add {
      display: inline;
      font-family: "Oxygen";
      font-style: normal;
      font-weight: 700;
      font-size: 15px;
      margin-left: 20px;
    }
  }
  .new_item_crsl {
    position: relative;
    width: 209px;
    height: 281px;
    background: #f3f4f7;
    float: left;
    text-align: center;
    border: 1px solid #f3f4f7;
    box-shadow: 0px 2px 6px 2px rgba(44, 56, 82, 0.1);
    border-radius: 6px;
    .ico {
      cursor: pointer;
      padding-top: 100px;
      color: #c5d0e4;
      font-size: 50px;
    }
  }
  .void_item {
    position: relative;
    width: 209px;
    height: 281px;
    background: #ffffff;
    float: left;
    text-align: center;
    margin: 0;
    opacity: 0.5;
    box-shadow: 0px 2px 6px 2px rgba(44, 56, 82, 0.1);
    border-radius: 6px;
  }
}
@media screen and (max-width: 1024px) {
  .new_item_crsl,
  .void_item {
    width: 179px !important;
    height: 251px !important;
  }
}

@media (max-width: 1300px) {
  .folder {
    width: 100%;
  }

  .latest-posts {
    width: 88%;
    .row:nth-child(2) {
      overflow-x: scroll;
    }
  }

  .new_item_crsl {
    height: 100% !important;
  }
}
</style>
