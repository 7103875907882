<template>
  <v-container 
		fluid 
		class="pa-0 ma-0 container-main"
    style="height: 100%;"
	>
    <div v-if="isScreensizeLess" class="d-flex justify-center align-center" style="height: 100%; background-color: #00a5ff;">
      <screensize-modal/>
    </div>
    <v-row dense v-if="!isScreensizeLess">
      <v-col>
        <modules />
        <folder />
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import Modules from "../components/modules/modules";
import Folder from "../components/folder/Folder";
import { ScreensizeModal } from "@design/styleguide";
export default {
  name: "Main",
  components: { Folder, Modules, ScreensizeModal},
  data: () => ({
    dogs: JSON.parse(localStorage.getItem("dogs")) || [],
    isScreensizeLess: false,
  }),
  methods: {
    getDimensions() {
      this.width = document.documentElement.clientWidth;
      (this.width < 1024) ? this.isScreensizeLess = true : this.isScreensizeLess = false;
    },
  },
  created() {
    this.$store.dispatch("hello");
    this.getDimensions();
    window.addEventListener("resize", this.getDimensions);    
  },
};
</script>
<style scoped>
	.container-main{
		overflow: hidden;
	}
</style>
