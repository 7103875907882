import Vue from "vue";
import VueRouter from "vue-router";
import Main from "../views/Main";
import FolderPost from "../views/folder/FolderPost";
import FolderAddon from "../views/folder/FolderAddon";
import AddonForm from "../views/AddonForm";

Vue.use(VueRouter);

const routes = [
  {
    path: "/main",
    name: "main",
    component: Main,
  },
  {
    path: "/main/post/folder/:id",
    name: "PostFolder",
    component: FolderPost,
  },
  {
    path: "/main/addon/folder/:id",
    name: "AddonFolder",
    component: FolderAddon,
  },
  {
    path: "/main/addon-form/:uuid",
    name: "FormAddon",
    component: AddonForm,
    meta: {
      requiresAuth: false,
    },
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
