<template>
  <div class="container">
    <v-row justify="center">
      <v-col class="d-flex justify-center">
        <div v-if="!showConfirmation" class="preview-general">
          <preview-background :image="image" />
          <preview-logo :message="message" />
          <div class="pa-5">
            <preview-questions
              v-for="question in questions"
              :key="question.id"
              :question="question"
              @text-open="openTextAnswer"
              @date-item="dateAnswer"
              @range-item-answer="rangeAnswer"
              @radio-button-answer="radioButtonAnswer"
              @checkboxes-answers="checkboxesAnswers"
            />
          </div>
          <div class="d-flex justify-center">
            <v-btn
              :color="design.buttoncolor"
              class="mb-4"
              :href="message.button.url"
              :loading="loading"
              @click="sendForm(message.button.name)"
            >
              <span :style="{ color: design.buttontextcolor }">
                {{ message.button.name }}
              </span>
            </v-btn>
            <!-- <v-btn color="primary" class="mb-4" @click="$emit('show-message', true)">{{ message.button.name }}</v-btn> -->
            <!-- :style="{ background: design.backcolor}" -->
          </div>
        </div>
        <div v-else class="confirmation-style">
          <confirmation-message :text="confirmationMessage" />
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import {
  PreviewLogo,
  PreviewBackground,
  ConfirmationMessage,
  PreviewQuestions,
  mapActions,
  Swal,
} from "@design/styleguide";

export default {
  name: "AddonForm",
  components: {
    PreviewLogo,
    PreviewBackground,
    ConfirmationMessage,
    PreviewQuestions,
  },
  metaInfo() {
    return {
      title: `Isocialcube | ${this.getNameAddon()}`,
      meta: [
        {
          name: "description",
          content: "Isocialcube",
        },
        {
          property: "og:title",
          content: `Isocialcube | ${this.getNameAddon()}`,
        },
        {
          property: "og:image",
          content: this.getImageAddon(),
        },
        { property: "og:site_name", content: "Isocialcube" },
        { property: "og:type", content: "website" },
        { name: "robots", content: "index,follow" },
      ],
    };
  },
  data() {
    return {
      questions: [],
      questionsGeneral: [],
      message: {
        title: "",
        description: "",
        button: {
          name: "",
          url: "",
        },
      },
      image: {},
      design: {
        backcolor: "#F3F4F7",
        buttoncolor: "#96A0B8",
        buttontextcolor: "#FFFFFF",
      },
      idsRequiredQuestions: [],
      addonId: null,
      confirmationMessage: "",
      showConfirmation: false,
      loading: false,
    };
  },
  methods: {
    ...mapActions({
      getAddon: "addons/saved/FIND_ADDON",
      addLead: "addons/lead/CREATE",
      createComponentMetric: "addons/saved/CREATE_ADDON_METRICS",
    }),

    getNameAddon() {
      return this.message.title || "ADDON";
    },
    getImageAddon() {
      return (
        this.image.path ||
        "https://listen-images.s3.us-east-2.amazonaws.com/logo.png"
      );
    },
    async sendForm(buttonName) {
      if (buttonName === "Send") {
        //*Validation Form
        const isCorrectForm = this.validationQuestions();
        if (!isCorrectForm) {
          Swal.fire({
            title: "Oops...",
            text: "Fields are required!",
            icon: "error",
            showConfirmButton: false,
            timer: 1500,
          });
          return;
        }
        this.loading = true;
        let templateQuestions = [];
        // create template
        for (const question of this.questions) {
          if (question.type < 5 || question.type > 6) {
            templateQuestions.push({
              questionId: question.idQuestionDb,
              response: "",
            });
          }
        }
        for (const question of this.questionsGeneral) {
          for (const template of templateQuestions) {
            if (question) {
              if (question.idQuestion === template.questionId) {
                if (question.answer) template.response = question.answer;
                else template.response = question.answers.join(", ");
              }
            }
          }
        }
        // save a new lead
        const resp = await this.saveLead(this.addonId, templateQuestions);
        if (!resp) {
          this.loading = false;
          return;
        }
        this.loading = false;
        this.showConfirmation = true;
      } else {
        console.log("another button ");
      }
    },
    openTextAnswer(id, idQuestion, question, answer, required) {
      this.questionsGeneral[id - 1] = {
        id,
        idQuestion,
        question,
        answer,
        required,
      };
    },
    dateAnswer(id, idQuestion, question, answer, required) {
      this.questionsGeneral[id - 1] = {
        id,
        idQuestion,
        question,
        answer,
        required,
      };
    },
    rangeAnswer(id, idQuestion, question, answer, required) {
      this.questionsGeneral[id - 1] = {
        id,
        idQuestion,
        question,
        answer,
        required,
      };
    },
    radioButtonAnswer(id, idQuestion, question, answer, required) {
      this.questionsGeneral[id - 1] = {
        id,
        idQuestion,
        question,
        answer,
        required,
      };
    },
    checkboxesAnswers(id, idQuestion, question, answers, required) {
      this.questionsGeneral[id - 1] = {
        id,
        idQuestion,
        question,
        answers,
        required,
      };
    },
    async setVariablesAddon() {
      const {
        params: { uuid },
      } = this.$router.currentRoute;
      if (!uuid) return;
      const {
        id,
        title,
        description,
        message,
        logo,
        dataObjects: { buttonMessage, image, x, y, transform },
        questions,
        styles,
      } = await this.getAddon({ uuid });
      this.addonId = id;
      this.confirmationMessage = message;
      this.design = {
        backcolor: styles.backcolor,
        buttoncolor: styles.buttoncolor,
        buttontextcolor: styles.buttontextcolor,
      };
      this.message = {
        title,
        description,
        button: { name: buttonMessage.name, url: buttonMessage.url },
        logo,
      };
      this.questions = this.refactorQuestions(questions);
      // getting ids and required from from questions
      for (const question of this.questions) {
        if (
          (question.type < 5 || question.type > 6) &&
          question.required === true
        ) {
          this.idsRequiredQuestions.push({
            id: question.id,
            required: question.required,
          });
        }
      }
      const dataImage = {
        ...image,
        x,
        y,
        transform: { ...transform },
      };
      this.image = this.refactorImage(dataImage);
    },
    refactorQuestions(itemsQuestions) {
      let questions = [];
      if (itemsQuestions.length > 0) {
        itemsQuestions.forEach((el, index) => {
          questions.push({
            id: index + 1,
            idQuestionDb: el.id,
            type: el.question,
            required: el.required,
            question: el.textQuestion,
            position: el.position,
            ...el.options,
          });
        });
        return questions;
      } else {
        return questions;
      }
    },
    refactorImage(image) {
      const {
        id,
        thumbnails,
        width,
        height,
        x,
        y,
        transform: { scale, rotate },
        locked,
      } = image;

      if (!thumbnails) return {};

      const currentImage = thumbnails.find(
        (item) => item.quality === "SCREEN_4"
      );
      const flip = `scale(${scale.x}, ${scale.y})`;
      const translate = `translate(${x}px,${y}px)`;
      const rotateDeg = `rotate(${rotate}deg)`;

      const transform = [translate, rotateDeg, flip];
      return {
        id,
        styles: {
          width: width + "px",
          height: height + "px",
          transform: transform.join(" "),
          // transition: '.4s '
        },
        locked,
        path: currentImage.location,
      };
    },
    validationQuestions() {
      // 1.- if idsReq array does not exist   pass true
      // 2.- if ids Req array exists, pass validation and  check if  questionGeneral array exists, if not pass false
      if (this.idsRequiredQuestions.length > 0) {
        if (this.questionsGeneral.length == 0) return false;
        let confirmQuestions = [];
        for (const question of this.questionsGeneral) {
          if (question) {
            if (question.required && question.answer) {
              confirmQuestions.push({
                id: question.id,
                required: question.required,
              });
            }
            if (question.required && question.answers) {
              if (question.answers.length > 0) {
                confirmQuestions.push({
                  id: question.id,
                  required: question.required,
                });
              }
            }
          }
        }
        if (this.idsRequiredQuestions.length != confirmQuestions.length)
          return false;
        return true;
      } else {
        return true;
      }
    },
    //* SAVE LEAD
    async saveLead(addonId, data) {
      const lead = {
        data,
        addonId,
      };
      const resp = await this.addLead({ lead });
      if (!resp) return false;
      return true;
    },
  },
  async created() {
    await this.setVariablesAddon();
    const { ref } = this.$route.query;
    const { uuid } = this.$route.params;
    if (ref && uuid) {
      await this.createComponentMetric({ uuid, ref });
    }
  },
  async mounted() {
    document.getElementById("navbar").remove();
  },
};
</script>

<style lang="scss" scoped>
.container {
  //height: -webkit-fill-available;
  .preview-general {
    width: 491px;
    border-color: #ffffff;
  }
  .confirmation-style {
    margin-top: 71px;
  }
}
</style>
