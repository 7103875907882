<template>
  <div>
    <v-menu class="menu-drop" attach offset-x>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          class="drop-button"
          :class="{ [classBackgroundColor]: even }"
          :style="{ 'margin-top': margTop, 'margin-left': margLeft }"
          small
          v-bind="attrs"
          v-on="on"
          color="white"
          :min-width="width"
          :min-height="height"
        >
          <i class="drop-button-icon" :class="iconButton"></i>
        </v-btn>
      </template>
      <v-list :width="widthBox">
        <v-list-item class="item" v-for="(item, index) in items" :key="index">
          <v-list-item-title
            class="item__title"
            @click="selectedItem(item.title)"
          >
            <i class="pl-2 pr-3 mt-2 ml-2" :class="item.icon"></i>
            {{ item.title }}
          </v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
  </div>
</template>
<script>
export default {
  name: "DropDownIcon",
  props: {
    items: {
      type: Array,
      default: () => {
        return [];
      },
    },
    iconButton: {
      type: String,
      default: "fas fa-ellipsis-h",
    },
    width: {
      type: Number,
      default: 20,
    },
    height: {
      type: Number,
      default: 34,
    },
    widthBox: {
      type: Number,
      default: 165,
    },
    margTop: {
      type: String,
      default: "0px",
    },
    margLeft: {
      type: String,
      default: "0px",
    },
    even: {
      type: Boolean,
      default: true,
    },
    classBackgroundColor: {
      type: String,
      default: "style-blue",
    },
  },
  data() {
    return {};
  },
  methods: {
    selectedItem(action) {
      this.$emit("selected-action", action);
    },
  },
};
</script>

<style lang="scss" scoped>
.drop-button {
  padding: 0px 9px !important;
  &-icon {
    font-weight: bold;
    font-size: 18px;
  }
  &:hover,
  &:focus {
    color: white;
  }
}
.item {
  min-height: 37px;
  padding: 0;
  &__title {
    cursor: pointer;
    padding-top: 5px;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 12px;
    min-height: 33px;
    &:hover {
      background: #ebf8ff;
    }
  }
}
.menu-drop {
  ::v-deep .v-list {
    padding: 0;
  }
}
.style-blue {
  &:focus::before,
  &:hover::before {
    background: #00a5ff;
    opacity: 1;
  }
}
.style-black {
  &:focus::before,
  &:hover::before {
    background: #2c3852;
    opacity: 1;
  }
}
</style>
