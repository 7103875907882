(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("vue-router"), require("vue"), require("@design/styleguide"));
	else if(typeof define === 'function' && define.amd)
		define(["vue-router", "vue", "@design/styleguide"], factory);
	else {
		var a = typeof exports === 'object' ? factory(require("vue-router"), require("vue"), require("@design/styleguide")) : factory(root["vue-router"], root["vue"], root["@design/styleguide"]);
		for(var i in a) (typeof exports === 'object' ? exports : root)[i] = a[i];
	}
})(window, function(__WEBPACK_EXTERNAL_MODULE__6389__, __WEBPACK_EXTERNAL_MODULE__8bbf__, __WEBPACK_EXTERNAL_MODULE_ad3f__) {
return 